const production = process.env.NODE_ENV === "production"

const actions = {
  pageview: () => {
    if (production && window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-717082457/MKw-CP6S6dABENme99UC"
      })
    } else {
      console.log("Google Ads || Page View")
    }
  },
  addTransaction: args => {
    if (production && window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-717082457/BtFECNTHh9EBENme99UC",
        value: args.value,
        currency: "USD",
        transaction_id: args.id || ""
      })
    } else {
      console.log(`Google Ads || Add Transaction: ${JSON.stringify(args)}`)
    }
  }
}

export default actions
