import ReactGA from "react-ga"

ReactGA.initialize("UA-97408879-13")

const production = process.env.NODE_ENV === "production"

const actions = {
  set: (params = {}) => {
    if (production) {
      ReactGA.set(params)
    } else {
      console.log(`Google Analytics || Set: ${JSON.stringify(params)}`)
    }
  },
  pageview: page => {
    if (production) {
      ReactGA.pageview(page)
    } else {
      console.log(`Google Analytics || Page View: ${page}`)
    }
  },
  addTransaction: args => {
    const { id, name, sku, price, revenue } = args

    if (production) {
      ReactGA.plugin.require("ecommerce")
      ReactGA.plugin.execute("ecommerce", "addItem", { id, name, sku, price })
      ReactGA.plugin.execute("ecommerce", "addTransaction", { id, revenue })
      ReactGA.plugin.execute("ecommerce", "send")
      ReactGA.plugin.execute("ecommerce", "clear")
    } else {
      console.log(
        `Google Analytics || Add Transaction: ${JSON.stringify(args)}`
      )
    }
  }
}

export default actions
