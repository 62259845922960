import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faUsers,
  faGlobeAmericas,
  faCalendarAlt,
  faRobot,
  faVenusMars
} from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"

library.add(faVenusMars)
library.add(faRobot)
library.add(faCalendarAlt)
library.add(faGlobeAmericas)
library.add(faUsers)

library.add(fab)
