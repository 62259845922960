import React, { useEffect } from "react"
import styled from "styled-components/macro"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import useReactRouter from "use-react-router"

import GlobalStyles from "styles/globalStyles"
import FullScreenLoading from "components/FullScreenLoading"
import ScrollToTop from "components/ScrollToTop"
import GA from "services/ga"
import GAds from "services/gAds"
import FBPixel from "services/facebookPixel"

import Header from "./components/Header"

const Landing = React.lazy(() => import("scenes/Landing"))
const Checkout = React.lazy(() => import("scenes/Checkout"))
const Reports = React.lazy(() => import("scenes/Reports"))

const GoogleAnalytics = () => {
  const { location } = useReactRouter()
  useEffect(() => {
    GA.pageview(location.pathname)
    GAds.pageview()
    FBPixel.pageView()
  }, [location])
  return <> </>
}

const Content = styled.div`
  margin-top: 85px;
`

export default function App() {
  return (
    <>
      <GlobalStyles />
      <React.Suspense fallback={<FullScreenLoading />}>
        <Router>
          <GoogleAnalytics />
          <ScrollToTop>
            <Header />
            <Content>
              <Switch>
                <Route path="/" exact component={Landing} />
                <Route path="/report/:reportName" exact component={Reports} />
                <Route path="/:id" component={Checkout} />
                {/* TODO: 404 Page */}
              </Switch>
            </Content>
          </ScrollToTop>
        </Router>
      </React.Suspense>
    </>
  )
}
