import React, { useEffect } from "react"
import styled from "styled-components/macro"
import { Link } from "react-router-dom"

import logoImg from "assets/images/logo.png"

const Container = styled.div`
  width: 100%;
  height: 85px;
  border-bottom: 10px solid #5669b1;
  padding: 15px 30px;
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  z-index: 10;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

const LogoBox = styled(Link)`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
`

const Logo = styled.img.attrs({ src: logoImg })`
  height: 45px;
`

const Title = styled.h1`
  margin: 6px 0 0 10px;
  color: #5669b1;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1em;
  white-space: nowrap;

  @media screen and (max-width: 600px) {
    display: none;
  }
`

const NavLink = styled.a`
  color: black;
  font-size: 1.25rem;
  margin: 0 20px;
  line-height: 2.8rem;
`

export default function Header(props) {
  return (
    <Container>
      <Content>
        <LogoBox to="/">
          <Logo />
          <Title>Insta Data</Title>
        </LogoBox>
        <div>
          <NavLink href="#pricing">Pricing</NavLink>
          <NavLink href="#faq">FAQ</NavLink>
        </div>
      </Content>
    </Container>
  )
}
